/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'gatsby-plugin-intl'

import isMobile from '../../utils/isMobile'

import Button from '../Button'
import Text from '../Text'

import locationIcon from '../../images/ic-location.svg'
import star from '../../images/star.png'
import starFilled from '../../images/star-filled.png'

const Container = styled.div`
  ${({ theme }) => css`
    border: 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 16px 26px;

    ${theme.queries.medium} {
      padding: 20px 20px 30px;
    }
  `}
`

const Star = ({ filled }) => (
  <img
    src={filled ? starFilled : star}
    alt="pontuação"
    css={css`
      margin-right: 6px;
    `}
  />
)

const Stars = ({ quantity }) => {
  return (
    <div>
      <Star filled={quantity > 0} />
      <Star filled={quantity > 1} />
      <Star filled={quantity > 2} />
      <Star filled={quantity > 3} />
      <Star filled={quantity > 4} />
    </div>
  )
}

const Actions = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.colors.celestialblue};
    display: flex;
    justify-content: space-between;

    div {
      display: flex;

      img {
        margin-right: 10px;
      }
    }

    ${theme.queries.small} {
      div {
        min-width: 90px;
      }
    }
  `}
`

const StyledText = styled(Text)`
  ${({ theme }) => css`
    // font-family: 'Above',sans-serif;
    margin-bottom: 10px;
  `}
`

const Location = ({ distance }) => (
  <Text color="celestialblue" size="14">
    {distance} km
  </Text>
)

const InstituteInfo = ({
  tradingName,
  distance,
  averageScore,
  evaluationCount,
  clickFunction,
}) => {
  return (
    <Container onClick={clickFunction}>
      <div
        css={css`
          display: flex;
          align-items: center;
          margin-bottom: 10px;
        `}
      >
        <Stars quantity={averageScore} />{' '}
        <Text
          color="orange"
          size="14"
          css={css`
            margin-left: 6px;
          `}
        >
          ({evaluationCount})
        </Text>
      </div>
      <div>
        <StyledText
          as="h4"
          size="21"
          weight="500"
          lineHeight="30"
          mobileSize="16"
          mobileHeight="20"
          title={tradingName}
        >
          {tradingName}
        </StyledText>
      </div>
      <Actions>
        <div>
          <img src={locationIcon} alt="Ícone de distâncias" />
          <Location
            distance={distance > 50 ? Math.floor(distance) : distance}
          />
        </div>
        <Button
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 0.97 }}
          uppercase
          size={isMobile() ? 'small' : 'default'}
        >
          <FormattedMessage id="donate_now" />
        </Button>
      </Actions>
    </Container>
  )
}

Star.propTypes = {
  filled: PropTypes.bool.isRequired,
}

Stars.propTypes = {
  quantity: PropTypes.number.isRequired,
}

Location.propTypes = {
  distance: PropTypes.number.isRequired,
}

InstituteInfo.propTypes = {
  clickFunction: PropTypes.func,
  name: PropTypes.string.isRequired,
  tradingName: PropTypes.string.isRequired,
  distance: PropTypes.number.isRequired,
  averageScore: PropTypes.number.isRequired,
  evaluationCount: PropTypes.number.isRequired,
}

InstituteInfo.defaultProps = {
  clickFunction: () => {},
}

export default InstituteInfo
