/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { FormattedMessage } from 'gatsby-plugin-intl'

import Wrapper from '../../../Wrapper'
import FlexContainer from '../../../FlexContainer'
import Title from '../../../Title'
import Text from '../../../Text'

import { Container, Content } from './styles'

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "hero-background.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      figure: file(relativePath: { eq: "hero-home-figure-gift.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Wrapper>
        <FlexContainer
          css={theme => css`
            align-items: center;

            ${theme.queries.small} {
              flex-direction: column;
              align-items: center;

              div + div {
                margin-top: 40px;
              }
            }
          `}
        >
          <Content>
            <Title
              mobileAlign="center"
              mobileSize="28"
              mobileHeight="35"
              marginBottom="20"
            >
              <FormattedMessage id="pages.institutions.title" />
            </Title>
            <Text
              size="20"
              weight="300"
              lineHeight="32"
              mobileSize="14"
              mobileHeight="22"
              mobileAlign="center"
              marginBottom="20"
            >
              <FormattedMessage id="pages.institutions.text1" />
            </Text>
            <Text
              size="20"
              weight="300"
              lineHeight="28"
              mobileSize="14"
              mobileHeight="22"
              mobileAlign="center"
            >
              <FormattedMessage id="pages.institutions.text2" />
            </Text>
          </Content>
          <Img
            loading="eager"
            fluid={data.figure.childImageSharp.fluid}
            css={theme => css`
              width: 90%;
              ${theme.queries.medium} {
                transform: translateX(50px);
                width: 1010px;
              }
            `}
          />
        </FlexContainer>
      </Wrapper>
    </Container>
  )
}

export default Hero
