/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { navigate, Link, useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import { toast } from 'react-toastify'
import { setCurrentInstituteRequest } from '../../../../store/modules/institution/actions'
import {
  fetchInstitutions,
  queryInstitutions,
} from '../../../../services/institutions'

import Wrapper from '../../../Wrapper'
import Spacing from '../../../Spacing'
import Title from '../../../Title'
import SearchInput, { SearchContainer } from '../../../SearchInput'
import InstituteInfo from '../../../InstituteInfo'
import Card from '../../../Card'
import Loader from '../../../Loader'

const ListContainer = styled.div`
  ${({ theme }) => css`
    ${theme.queries.medium} {
      display: grid;
      grid-column-gap: 30px;
      grid-row-gap: 60px;
      grid-template-columns: repeat(2, 1fr);
      margin: 20px auto;
    }
    ${theme.queries.large} {
      grid-template-columns: repeat(3, 1fr);
      margin-bottom: 80px;
    }
  `}
`

const Listing = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { list: institutions, totalPages } = useSelector(
    state => state.institution
  )

  const [searchList, setSearchList] = useState([])
  const [loadedInstitution, setLoadedInstitution] = useState(institutions)

  const [page, setPage] = useState(2)
  const [loading, setLoading] = useState(false)

  const loadInstitution = async () => {
    setLoading(true)
    try {
      const response = await fetchInstitutions(page)
      const newInstitutions = response.data.results
      setLoadedInstitution([...loadedInstitution, ...newInstitutions])
    } catch (err) {
      toast.error(err)
    }
    setLoading(false)
  }

  const handleScroll = () => {
    const heightValidation =
      window.innerHeight + document.documentElement.scrollTop + 600 <
      document.documentElement.offsetHeight

    const pageValidation = page > totalPages

    if (heightValidation || pageValidation || loading) {
      return
    }

    setPage(page + 1)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    loadInstitution()
  }, [page])

  const handleChange = async ({ target }) => {
    try {
      const { value } = target

      if (value.length === 0) {
        setSearchList([])
        return
      }

      const response = await queryInstitutions(value)
      setSearchList(response.data.results)
    } catch (error) {
      setSearchList([{}])
    }
  }

  const handleSelectInstitution = id => {
    dispatch(setCurrentInstituteRequest(id))
    navigate(`/instituicoes/doar/${id}/`)
  }

  return (
    <Wrapper>
      <SearchContainer>
        <SearchInput
          minLength={2}
          debounceTimeout={100}
          placeholder={intl.formatMessage({
            id: 'pages.institutions.search',
          })}
          onChange={handleChange}
        />

        <ul>
          {searchList.map(item => {
            if (!item.id) {
              return (
                <li>
                  <Link to="/" onClick={e => e.preventDefault()}>
                    <em>
                      <FormattedMessage id="no_result_found" />
                    </em>
                  </Link>
                </li>
              )
            }
            return (
              <li key={item.id}>
                <Link
                  to={`/instituicoes/doar/${item.id}/`}
                  css={theme => css`
                    ${theme.queries.small} {
                      display: flex;
                      flex-direction: column;
                    }
                  `}
                >
                  <strong>{item.tradingName}</strong> - {item.city}
                </Link>
              </li>
            )
          })}
        </ul>
      </SearchContainer>

      <Spacing top="80" bottom="252" topMobile="40" bottomMobile="40">
        <Title
          as="h3"
          color="celestialblue"
          mobileSize="28"
          mobileAlign="center"
        >
          <FormattedMessage id="next_to_me" />
        </Title>

        <ListContainer>
          {loadedInstitution.map(institution => {
            return (
              <Card
                className="listing"
                key={institution.id}
                upImagesrc={institution.imagePath}
                clickFunction={() => handleSelectInstitution(institution.id)}
                animate
              >
                <InstituteInfo
                  clickFunction={() => handleSelectInstitution(institution.id)}
                  city={institution.city}
                  state={institution.state}
                  id={institution.id}
                  name={institution.name}
                  tradingName={institution.tradingName}
                  distance={institution.distance}
                  isFollowing={institution.isFollowing}
                  averageScore={institution.averageScore}
                  evaluationCount={institution.evaluationCount}
                />
              </Card>
            )
          })}
        </ListContainer>
        {loading && (
          <div
            css={css`
              display: flex;
              justify-content: center;
            `}
          >
            <Loader />
          </div>
        )}
      </Spacing>
    </Wrapper>
  )
}

export default Listing
